<template>
  <div class="system-setting">
    <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>
    <a-form ref="formRef"
            :model="form"
            :rules="rules"
            :validateTrigger="['submit', 'blur']"
            hideRequiredMark>
      <a-steps direction="vertical">
        <a-step status="wait">
          <template #title>
            <p class="mb-8">管理员登录
              <a-button type="link"
                        target="_blank"
                        href="https://work.weixin.qq.com/wework_admin/frame#index">
                企业微信后台
              </a-button>
              ，进入「我的企业」页面，找到“企业ID”项，复制并填写到下面：
            </p>

            <a-form-item label="企业ID"
                         name="corpId">
              <a-input v-model:value="form.corpId"
                       placeholder="企业ID"></a-input>
            </a-form-item>
          </template>
        </a-step>

        <a-step>
          <template #title>
            管理员登录 <a-button type="link"
                      target="_blank"
                      href="https://work.weixin.qq.com/wework_admin/frame#index">
              企业微信后台
            </a-button>，找到菜单「客户联系」-「客户」，展开API，查看secret。
          </template>
          <template #description>
            注：教育机构管理后台的菜单名称为「学员联系」 - 「学员」。
          </template>
        </a-step>

        <a-step>
          <template #title>
            <p class="mb-8">将获得的secret粘贴到下面。</p>

            <a-form-item label="Secret"
                         name="secret">
              <a-input v-model:value="form.secret"
                       placeholder="Secret"></a-input>
            </a-form-item>
          </template>
        </a-step>

      </a-steps>
    </a-form>

    <div class="save-btn">
      <a-button class="btn"
                type="primary"
                centered
                :loading="btnLoading"
                @click="onSubmit">
        保存
      </a-button>
    </div>

  </div>
</template>

<script>
import { message, Steps } from "ant-design-vue";

import RouterHeader from "@/components/RouterHeader";
import { reactive, ref } from "vue";

import corpSettingApi from "@/service/api/corpSetting";

export default {
  name: "ChatToolbar",
  components: {
    ASteps: Steps,
    AStep: Steps.Step,
    RouterHeader,
  },
  setup() {
    const routerHeaderInfo = [
      {
        path: "/enterprise/systemSetting",
        name: "系统设置",
      },
      { name: "客户联系" },
    ];
    const form = reactive({
      secret: "",
      corpId: "",
    });

    const formRef = ref();
    const rules = {
      secret: [
        {
          required: true,
          message: "请输入secret",
          whiteSpace: true,
        },
      ],
    };
    const btnLoading = ref(false);
    function onSubmit() {
      formRef.value
        .validate()
        .then(async () => {
          // TODO: 验证客户联系secret的有效性，若错误则给出提示。
          btnLoading.value = true;
          const { secret, corpId } = form;
          await corpSettingApi.saveCorpWechatInfo({
            clientApiSecret: secret,
            workWechatCorpId: corpId,
          });

          // TODO: 客户配置完，保存后，触发系统通过secret token去同步机构/员工/客户/客户群/标签数据。
          message.success("修改成功");
        })
        .finally(() => {
          btnLoading.value = false;
        });
    }

    async function getSecret() {
      await corpSettingApi.getCorpWorkWechatBaseInfo().then((res) => {
        form.secret = res.clientApiSecret;
        form.corpId = res.workWechatCorpId;
      });
    }

    getSecret();
    return {
      routerHeaderInfo,
      form,

      formRef,
      rules,
      btnLoading,
      onSubmit,
    };
  },
};
</script>
<style lang="less" scoped>
.system-setting .ant-steps-item-wait {
  min-height: 132px;
}
.ant-input {
  width: 320px;
}
.save-btn {
  position: absolute;
  left: 50%;
  bottom: 32px;
  margin-left: -62px;
}
</style>
